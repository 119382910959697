// extracted by mini-css-extract-plugin
export var calculator = "Calculator-module--calculator--hPXKe";
export var calculatorInput = "Calculator-module--calculatorInput--3SWu5";
export var calculatorLabel = "Calculator-module--calculatorLabel--36E2l";
export var calculatorSelect = "Calculator-module--calculatorSelect--2_RRg";
export var calculatorSubmit = "Calculator-module--calculatorSubmit--NRdvI";
export var error = "Calculator-module--error--3XYOp";
export var inputContainer = "Calculator-module--input-container--1m1Qa";
export var paymentSchedule = "Calculator-module--paymentSchedule--2iMPH";
export var requestModal = "Calculator-module--requestModal--1-EJ7";
export var uiSelect = "Calculator-module--uiSelect--21pRD";